import React from "react"
import { IndexPage } from "./index"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"


const Page = (props) => {
  const { gbp } = useStaticQuery(query).site.siteMetadata.pricingConfiguration;

  return (
    <IndexPage
      location={props.location}
      checkoutUrls={gbp}
    />
  )
}

const query = graphql`
query {
  site {
    siteMetadata {
      pricingConfiguration {
        gbp {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
          free {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}`

export default Page
